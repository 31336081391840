import React from "react";
import { Progress } from "reactstrap";

const ProgressBar = ({
  progressPercent,
  animated = true,
  colorTheme = "info",
}) => {
  return (
    <div className="my-2 px-4 mx-auto">
      <Progress
        value={progressPercent}
        animated={animated}
        color={colorTheme}
        style={{
          height: "3rem",
          fontWeight: "bold",
          fontSize: "1.25rem",
        }}
      >
        {progressPercent}% komplett
      </Progress>
    </div>
  );
};

export default ProgressBar;
